body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.box {
  border: 2px solid;
  width: 50%;

  display: flex;
  justify-content: center;
  margin-right: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  padding-left: 5%;
}

.css-5y431e {
  width: 50% !important;
}

.css-1g807k0 {
  width: 50% !important;
}

.no-data-msg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  overflow: hidden;
}

.icon-round-120 {
  width: 120px;
  height: 120px;
  border: 1px solid #999999;
  border-radius: 50%;
}
.icon-round-120 svg{
  width: 80px;
  height: 80px;
}
.ptb-15{
  padding: 15px 0;
}