/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

:root {
  --primaryColor: #1b2324;
  --secondaryColor: #767b7c;
  --textColor: #1b2324;
  --poppinsFont: "Poppins", sans-serif;
  --grayColor: #808080;
  --white: #ffffff;
  --blue1: #355f92;
  --blue2: #1e3653;
  --skeleton: #ededed;
}

.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

* {
  box-sizing: border-box;
  scrollbar-color: #999999 var(--white);
  scrollbar-width: thin;
  -ms-overflow-style: none;
}

html,
body {
  scroll-behavior: smooth;
}

table,
tr,
td,
th {
  padding: 0px;
  margin: 0px;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-60 {
  padding-top: 60px;
}
.pt-2 {
  padding-top: 16px;
}
.pt-3 {
  padding-top: 24px;
}

.m-0 {
  margin: 0 !important;
}

.mt-5 {
  margin-top: 40px !important;
}

.mt-4 {
  margin-top: 32px !important;
}

.mt-3 {
  margin-top: 24px !important;
}

.mt-2 {
  margin-top: 16px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-1 {
  margin-top: 8px;
}

.mb-1 {
  margin-bottom: 8px;
}

.mb-2 {
  margin-bottom: 16px !important;
}

.mb-3 {
  margin-bottom: 24px;
}

.mb-5 {
  margin-bottom: 40px !important;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mt-32 {
  margin-top: 32px !important;
}

.ml-1 {
  margin-left: 8px;
}

.ml-2 {
  margin-left: 16px;
}

.ml-3 {
  margin-left: 24px;
}
.ml-4 {
  margin-left: 36px;
}
.ml-6 {
  margin-left: 48px;
}
.ml-55 {
  margin-left: 55px;
}

.mr-1 {
  margin-right: 8px;
}

.mr-2 {
  margin-right: 16px;
}

.mr-3 {
  margin-right: 24px;
}
.pl-2 {
  padding-left: 16px;
}
.padding-24 {
  padding: 24px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pb-24 {
  padding-bottom: 24px;
}
.pb-32 {
  padding-bottom: 32px;
}
.pl-24 {
  padding-left: 24px;
}
.pl-32 {
  padding-left: 32px;
}

.mt-1 {
  margin-top: 8px;
}
.w-50 {
  width: 50%;
}
.w-20 {
  width: 20%;
}
.w-10 {
  width: 10%;
}
.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100%;
  position: relative;
}

.relative {
  position: relative;
}

.m-10p {
  margin: 10px;
}

.form-field-height {
  min-height: 110px;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center !important;
}

.disabled {
  opacity: 0.5;
  pointer-events: none !important;
}

body {
  font-family: var(--poppinsFont);
  height: 100%;
  width: 100%;
}
.scroll-auto {
  overflow: auto;
  display: flex;
  height: 100%;
  flex-direction: column;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0, 0.4);
  border-radius: 6px;
}

html,
.scrollableTarget,
.permission-container,
.gwScrollBar {
  scrollbar-color: #999999 var(--white);
  scrollbar-width: thin;
  -ms-overflow-style: none;
}

.inner-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.gw-link {
  color: var(--blue1);
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  z-index: 4;
}
.gw-no_link {
  color: #1b2324;
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  z-index: 4;
}

.gw-link:hover {
  text-decoration: underline;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.align-items-center {
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-flex-start {
  align-items: flex-start;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.flex1 {
  flex: 1;
}

.gap-1 {
  gap: 0.5rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-3 {
  gap: 1.5rem;
}
.gap-4 {
  gap: 2rem;
}

.mx-w-250 {
  max-width: 250px;
}

.mx-w-450 {
  max-width: 450px;
}

.maxW-50 {
  max-width: 50%;
}

@media screen and (max-width: 900px) {
  .maxW-50 {
    max-width: 100%;
  }
}

.section-common-padding {
  padding: 40px 0;
}

.btnGroup-custom-container {
  max-width: 70%;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .section-common-padding {
    padding: 40px 0;
  }
}

label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.form-styles {
  flex: 1;
  width: 100%;
  background: #ffffff;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex-direction: column;
}

/* inputStyling */

.gritwell-input label {
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--textColor);
  font-family: var(--poppinsFont);
}

.gritwell-input input {
  display: block;
  padding: 0.375rem 0.75rem;
  height: 32px;
  font-size: 14px;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 8px;
  font-family: var(--poppinsFont);
  margin: 6px 0 4px 0 !important;
  background-color: #f5f7f7;
  border: 1px solid transparent;
  border-radius: 4px;
}

.gritwell-input.input-with-icon input {
  padding-right: 48px !important;
}

input.inputCustomHeight {
  min-height: 48px;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.gritwell-input input:focus {
  border: 1px solid var(--blue1) !important;
  background-color: #ffffff !important;
}

.gritwell-input input:hover {
  background-color: #f5f7f7 !important;
}

.gritwell-input input:focus-visible,
input:focus-visible {
  outline: none;
}

.select-style .react-datepicker-wrapper {
  width: 100%;
}

.permissionslistCustom .permissions-box .select-box {
  min-width: 100px !important;
  margin-top: 0px !important;
  max-width: 100% !important;
  width: 100% !important;
}

.permissionslistCustom .permissions-box .select-box div:nth-child(2) {
  height: unset;
  min-height: unset !important;
}

.spinner-container {
  height: 100%;
  position: relative;
}

.spinner-container .spinner {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

button.gritwell-btn {
  min-width: 140px !important;
  padding: 2px;
  height: unset;
  font-weight: 600;
}

.css-gtsbif {
  background: transparent !important;
}

.scrollableTarget {
  overflow: auto !important;
  overflow-x: hidden !important;
}

.statusLabel {
  font-size: 16px;
  font-family: var(--poppinsFont);
  font-weight: 500;
  color: #111a1c !important;
  line-height: 1.5;
  margin: 0 8px;
}

table,
.td p {
  overflow: hidden;
}

.activeStatus svg {
  margin-right: 6px;
}

.truncate {
  display: block;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}

.textColorNeutral800 {
  color: #1b2324;
}

.textColorNeutral400 {
  color: #68787a;
}

.profileEditUpload:hover .hoverWrapper {
  display: flex !important;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  opacity: 1;
}

textarea:not(:focus).TabsTextArea {
  border: 1px solid #e0e0e0 !important;
  background-color: #ffffff !important;
}

section[role="dialog"] {
  border-radius: 20px !important;
  background-color: #ffffff;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.12) !important;
}

.grid-table textarea:not(:focus),
.grid-table input {
  border: 1px solid #f2f2f2 !important;
  background-color: #ffffff !important;
  padding: 4px;
}

.modalCustomSelect .react-select__control input {
  border: unset !important;
  background-color: unset !important;
}

.grid-table .react-select__control {
  background-color: #ffffff !important;
  border: 1px solid #f2f2f2 !important;
  min-height: 48px !important;
}

.PhasesOfCareContentWrapp .phasesOfCareSelect .react-select__control {
  background-color: #ffffff !important;
  border: 1px solid #f2f2f2 !important;
  min-height: 48px !important;
}

.healthProfileTab {
  overflow: unset !important;
}

.healthProfileTab div[role="tabpanel"] .react-select__control {
  background-color: #ffffff !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #e0e0e0 !important;
  border-radius: 0 !important;
  min-height: 48px !important;
}

.healthProfileTab
  div[role="tabpanel"]
  .react-select__control
  .react-select__multi-value,
.customDisable .react-select__control .react-select__multi-value {
  border: 1px solid var(--blue1) !important;
  background: var(--blue1) !important;
}

.healthProfileTab
  div[role="tabpanel"]
  .react-select__control
  .react-select__multi-value
  .react-select__multi-value__label,
.customDisable
  .react-select__control
  .react-select__multi-value
  .react-select__multi-value__label {
  color: #ffffff !important;
  padding: 4px 0 4px 8px;
  font-family: var(--poppinsFont);
}

.customDisable
  .react-select__control
  .react-select__multi-value
  .react-select__multi-value__label {
  padding: 4px;
}

.customDisable
  .react-select__control
  .react-select__multi-value
  .react-select__multi-value__remove {
  display: none;
}

.healthProfileTab
  div[role="tabpanel"]
  .react-select__control
  .react-select__multi-value
  .react-select__multi-value__remove
  svg,
.customDisable
  .react-select__control
  .react-select__multi-value
  .react-select__multi-value__remove
  svg {
  fill: #ffffff !important;
}

.healthProfileTab
  div[role="tabpanel"]
  .react-select__control
  .react-select__multi-value {
}

.healthProfileTab
  div[role="tabpanel"]
  .react-select__control
  .react-select__indicators {
  display: none;
}

div[role="menu"] {
  min-width: 250px;
  max-height: 500px !important;
}

div[role="menu"] .header-menuProfile,
div[role="menu"] .customDropdownMenuTree {
  padding: 16px 0;
  width: 287px;
}

div[role="menu"] .header-menuProfile h3,
div[role="menu"] .customDropdownMenuTree h3 {
  margin: 0;
  padding: 0 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
}

div[role="menu"] .menu-items h3,
div[role="menu"] .customDropdownMenuTree h3 {
  margin: 0;
  padding: 0 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #767b7c;
}

.menu-group div[data-ds--menu--heading-item]:first-of-type {
  margin-top: 14px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: unset;
}

button[role="menuitem"] {
  color: #717171;
  font-weight: 400;
  font-size: 14px;
}

.header-menuProfile button[role="menuitem"] {
  padding: 12px 16px;
}

.customDropdownMenuTree .title {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #95a1a2;
  font-weight: 600;
  font-size: 16px;
}

.customDropdownMenuTree button[role="menuitem"] {
  padding: 12px 32px;
}

.header-menuProfile button[role="menuitem"] span[data-item-title],
.customDropdownMenuTree button[role="menuitem"] span[data-item-title] {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1b2324;
}

.header-menuProfile button[role="menuitem"]:hover span[data-item-title],
.customDropdownMenuTree button[role="menuitem"]:hover span[data-item-title] {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--blue1);
}

.customMenuSelect .title {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #95a1a2;
  font-weight: 600;
  font-size: 16px;
}

.customMenuSelect button[role="menuitem"] {
  padding: 12px 32px;
}

.header-menuProfile button[role="menuitem"] span[data-item-title],
.customMenuSelect button[role="menuitem"] span[data-item-title] {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1b2324;
}

.header-menuProfile button[role="menuitem"]:hover span[data-item-title],
.customMenuSelect button[role="menuitem"]:hover span[data-item-title] {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--blue2);
}

button[role="menuitem"]:hover {
  background-color: #eef0f4;
  color: var(--blue2);
  font-weight: 400;
  font-size: 14px;
}

button[role="menuitem"]:hover span svg path {
  fill: var(--blue2);
}

@-webkit-keyframes slidein {
  from {
    transform: translateY(-0%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slidein {
  from {
    transform: translateY(-0%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideout {
  from {
    transform: translateY(-0%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.after-edit {
  -webkit-animation: slidein 0.5s ease 1 normal;
  -moz-animation: slidein 0.5s ease 1 normal;
  -o-animation: slidein 0.5s ease 1 normal;
  animation: slidein 0.5s ease 1 normal;
}

.before-edit {
  -webkit-animation: slideout 0.5s ease 1 normal;
  -moz-animation: slideout 0.5s ease 1 normal;
  -o-animation: slideout 0.5s ease 1 normal;
  animation: slideout 0.5s ease 1 normal;
}

.acuityContainer {
  padding: 0 140px;
}

@media screen and (max-width: 1200px) {
  .acuityContainer {
    padding: 16px 116px;
  }
}

@media screen and (max-width: 900px) {
  .acuityContainer {
    padding: 16px 40px;
  }
}

.subscriptionHover:hover .subscriptionShow {
  display: flex !important;
}

.paymentView {
  color: var(--blue1);
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.myScheduleContainer .tabgroup {
  overflow: hidden;
  flex: 1;
}

.myScheduleContainer .tabgroup > div:first-child {
  height: 100%;
}

.noRecordDiv {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 74px;
  border: 1px dashed #e0e0e0;
}

.noRecordsStyles {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  justify-content: center;
}

.secondory-bg {
  background: #e5e5e5;
}

.MatTabGroup {
  width: 100%;
}

.statusDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  padding: 4px;
}

.dropDownHeightZero button {
  height: unset !important;
}

.defaultName {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #eef0f4;
  color: var(--blue2);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  text-transform: uppercase;
}
.defaultClientname {
  width: 160px;
  height: 160px;
  font-size: 50px;
  color: var(--blue2);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  text-transform: uppercase;
}

.defaultNameOnboard {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background: #eef0f4;
  color: var(--blue2);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  text-transform: uppercase;
}
.chatProfileName {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background: #eef0f4;
  color: var(--blue2);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.defaultLargeName {
  width: 160px;
  height: 160px;
  background: #eef0f4;
  color: var(--blue2);
  font-weight: 600;
  display: flex;
  font-size: 100px;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
  text-transform: uppercase;
}

.defaultHeaderName {
  width: 36px;
  height: 36px;
  font-size: 20px;
  border-radius: 50%;
  background: #eef0f4;
  color: var(--blue2);
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-transform: uppercase;
}
.defaultMessageName {
  width: 40px;
  height: 40px;
  font-size: 20px;
  border-radius: 50%;
  background: #eef0f4;
  color: var(--blue2);
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-transform: uppercase;
}

.alphaProfile {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #eef0f4;
  color: var(--blue2);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
.commented_main {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: #eef0f4;
  color: var(--blue2);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
.mySheduleSelect {
  background-color: #f8f8f8;
}
.in_active_Filter {
  border: none !important;
}
.customFilterDropdown {
  border-radius: 4px;
}

.customFilterDropdown
  .react-select__control
  .react-select__value-container
  div {
  color: #1b2324;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
}
.customFilterDropdown .react-select__menu div {
  color: #1b2324;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}
.customFilterDropdown svg {
  display: none;
}

.customSelectActive {
  border-radius: 3px;
  background: #f8fafc;
}

.customSelectInActive {
  border-radius: 3px;
  background: #e8e8e8;
}

.customSelectCompleted {
  border-radius: 3px;
  background: #e4eaf0;
}

.appointmentTable tbody.scrollableTarget {
  max-height: 400px !important;
}

.reactTable-customHeight tbody.scrollableTarget {
  max-height: 400px !important;
}

.spinner-bgColor {
  background-color: grey;
  opacity: 0.4;
}

.info-icon {
  cursor: help;
}

.info-icon svg {
  width: 18px;
  height: 18px;
}

.gw-required label:first-child:after {
  content: " *";
  color: var(--blue1);
  font-size: 16px;
}
.gwt-required span:first-child:after {
  content: " *";
  color: var(--blue1);
  font-size: 16px;
}

.back-block {
  cursor: pointer;
}

.MatchCareTeamTabGroup {
  overflow: unset !important;
}

.MatchCareTeamTabGroup div[role="tablist"] {
  justify-content: center;
}

.MatchCareTeamTabGroup div[role="tabpanel"] {
  margin-top: 40px;
  margin-bottom: 40px;
}

.MatchCareTeamTabGroup div[role="tablist"]::before {
  background-color: transparent !important;
}

.MatchCareTeamTabGroup div[role="tablist"] div[role="tab"] {
  color: #b0b0b0 !important;
}

.MatchCareTeamTabGroup div[role="tablist"] div[role="tab"]::after {
  content: "";
  border-radius: 2px;
  bottom: 0;
  margin: 0;
  position: absolute;
  width: inherit;
  left: 8px;
  right: 8px;
  border-bottom: 4px solid #b0b0b0;
}

.MatchCareTeamTabGroup
  div[role="tablist"]
  div[role="tab"][aria-selected="true"] {
  color: var(--blue1) !important;
}

.background-FAFAFC {
  background-color: #fafafc;
}

.matchRequestWrap {
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 32px;
  margin: 32px 0;
}

.dashboardTable {
  display: table;
  width: 100%;
  margin-top: 24px;
}

.dashboardCell {
  display: table-cell;
}

.dashboardCell {
  width: 350px;
  min-width: 120px;
  padding: 12px 18px;
  border-radius: 16px;
}

.dashboardHeader {
  background: #f9f8f9;
  border-radius: 10px;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #717171;
  letter-spacing: 0.08em;
}

.dashboardRow {
  background: #ffffff;
  border: 1px solid #e8eaf1;
  border-radius: 16px;
  margin: 8px 0;
}

.dashboardHeading {
  margin: 0px;
  font-weight: 500;
  color: #111a1c;
  font-size: 20px;
}

.modal-main .main-content {
  padding: 0 !important;
}

.customUploadButton button {
  background-color: transparent;
  color: var(--blue2) !important;
  border: 1px solid var(--blue2) !important;
}

.customUploadButton button[aria-expanded="true"] {
  background-color: transparent;
  color: var(--blue2) !important;
}

.customUploadButton button:hover,
.customUploadButton button:active span {
  color: #1b2324;
  background-color: transparent;
  color: var(--blue2) !important;
}

.borderedDropdown button {
  background: #fff !important;
  border-radius: 10px !important;
  border: 1px solid var(--blue2) !important;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  min-width: 120px;
  color: var(--blue2) !important;
}

.borderedDropdown button[aria-expanded="true"] {
  background-color: transparent;
  color: var(--blue2) !important;
}

.borderedDropdown button:hover {
  background: #eef0f4 !important;
  color: var(--blue2) !important;
  border: 1px solid var(--blue2) !important;
}

.borderedDropdown button:active span {
  color: #1b2324;
  background-color: transparent;
  color: var(--blue2) !important;
}

.testDate {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #717171;
  margin: 0;
}

.backToDashboard {
  margin-bottom: 24px;
}

.backToDashboard span {
  display: inline-flex;
  align-items: center;
}

.copyToText {
  font-family: var(--poppinsFont) !important;
  font-weight: 400;
  color: #ffffff;
}

.recommendedCustomClass {
  padding: 0 16px !important;
  overflow: hidden !important;
}

.recommendedCustomClass .dashboardTable .dashboardHeader {
  position: sticky;
  top: 0;
}

.bloodTestReportClass {
  padding-top: 40px;
}

.GW-paymentForRemarks {
  padding: 16px 0;
  border-bottom: 1px solid #bbc7ca;
}

.GW-paymentForRemarks div.d-flex {
  margin-bottom: 12px;
}

.GW-paymentForRemarks div.d-flex:last-child {
  margin-bottom: 0px;
}

.GW-paymentForRemarks .paymentForExtraInfo,
.GW-paymentForRemarks .paymentForExtraInfoPrice {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #767b7c;
}

.pagination-box {
  padding-top: 16px;
}

.Gw-paymentForHeader {
  gap: 8px;
  position: relative;
  padding-bottom: 16px;
}

.Gw-paymentForHeader .Gw-paymentForHeaderItem {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #494f50;
}

.customModalPopupEdit.modal-main {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.customModalPopupEdit .middle-wraper {
  height: unset !important;
  flex: 1;
  overflow: hidden;
  margin-bottom: 32px;
}

.customModalPopupEdit .middle-content {
  height: 100%;
  overflow: auto;
}

.customModalPopupEdit .showFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.bhuzhZ,
.ProgressFill-sc-__sc-zr62pz-1 {
  background-color: rgb(255 255 255 / 30%) !important;
}

.HomeTableContainer {
  min-height: 250px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border: 1px solid rgb(0 0 0 / 8%);
  border-radius: 16px;
  padding: 32px;
  position: relative;
  max-width: 1140px;
  margin: 0 auto;
}

@media screen and (max-width: 900px) {
  .HomeTableContainer {
    max-width: 100%;
  }
}

.homePageContainer {
  max-width: 1140px;
  margin: 0 auto;
}

@media screen and (max-width: 900px) {
  .homePageContainer {
    max-width: 100%;
    margin: 0 auto;
  }
}

.spinner-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.completedTaks {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #278c71;
  text-decoration-line: underline;
  cursor: pointer;
}

.type {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #484848;
}

.text-transform {
  text-transform: capitalize;
}

.noRecordDiv {
  display: flex;
  justify-content: center;
  width: 100%;
}

.noRecordsStyles {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  justify-content: center;
}

.linkinput input {
  background: #ffffff;
  border-radius: 20px !important;
}
.customInputIcon input {
  padding-right: 60px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.inputIcon {
  position: absolute;
  top: 20px;
  right: 10px;
}
.errorStyles {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 20px;
  color: #e24444 !important;
}
.seperatorIcon {
  position: relative;
  padding-right: 8px;
  margin-right: 8px;
}
.seperatorIcon::after {
  content: "";
  width: 1px;
  height: 23px;
  display: block;
  background: grey;
  position: absolute;
  right: 0;
  top: -2px;
}
.customEmail div[role="presentation"] {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.link-edit-input-wrapper {
  display: flex;
  flex-direction: column;
}

.link-edit-input-wrapper .link-edit-input {
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.link-edit-input-wrapper .link-edit-input input {
  border: none !important;
  margin: 0 !important;
}
.link-edit-input-wrapper .link-edit-input .custom-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ql-clipboard {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.submit-button {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 1;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}
.pdfCustomModal {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.pdfCustomModal .middle-wraper {
  overflow: auto;
}
.new-msgs {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #355f921a;
  border-radius: 50px;
}

.custom-grid-link {
  display: inline-flex;
  color: #355f92;
  text-decoration: none;
  cursor: pointer;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 101;
}

.currencyInput {
  padding: 14px 24px;
  gap: 8px;
  height: 48px;
  background: #f5f7f7;
  border-radius: 4px;
  border: none;
}
.reactTableSkel {
  height: 40px;
  background: var(--skeleton);
  margin-bottom: 10px;
}
.content1 {
  width: 216px;
  height: 22px;
  background: var(--skeleton);
  margin-bottom: 4px;
}
.content2 {
  width: 134px;
  height: 22px;
  background: var(--skeleton);
}
.conRow2 {
  height: 22px;
  background: var(--skeleton);
}
.conRow3_1 {
  width: 102px;
  height: 22px;
  background: var(--skeleton);
  margin-bottom: 2px;
}
.conRow3_2 {
  width: 160px;
  height: 22px;
  background: var(--skeleton);
}
.conRow4 {
  height: 22px;
  background: var(--skeleton);
}
.conRow5 {
  width: 133px;
  height: 22px;
  background: var(--skeleton);
  margin-bottom: 2px;
}
.conRow6 {
  height: 22px;
  background: var(--skeleton);
}
.recommendMainHeader {
  width: 250px;
  height: 36px;
  background: var(--skeleton);
  margin-bottom: 12px;
}
.recommendUpdate {
  width: 400px;
  height: 18px;
  background: var(--skeleton);
}
.recommendSearch {
  width: 350px;
  height: 48px;
  background: var(--skeleton);
}
.StripeElement--focus {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}
.successContent {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #272833;
}
.paymentSuccessTitle {
  font-weight: 400;
  margin: 0;
  font-size: 24px;
  line-height: 42px;
  margin-bottom: 16px;
}

.paymentBtnStyles {
  width: 300px;
  margin-top: 36px;
  border-radius: 16px !important;
}

.activeStatus {
  background: transparent;
  border-radius: 8px;
}
.activeStatus div button {
  background-color: #f5f7f7;
  border-radius: 8px;
  height: 42px;
  line-height: 40px;
  padding: 0 16px;
}
.payment-checkbox div label input {
  appearance: auto !important;
}
.planspageDropdown div button {
  background-color: #f5f7f7;
  height: 40px;
  border-radius: 8px;
}
.planspageDropdown div button:hover {
  background-color: #f5f7f7;
}
.chatScreenEditor .ql-editor {
  font-family: "Poppins";
  background: #fff !important;
  height: 220px;
  overflow: auto;
}


.ql-toolbar.ql-snow {
  background-color: #fff !important;
  border: 1px solid #e0e0e0 !important;
  padding: 8px !important;
}
.chatScreenEditor .ql-editor p {
  font-family: "Poppins";
}
.chatScreenEditor a {
  color: blue !important;
  cursor: pointer;
}
.chatScreenEditor .ql-editor[data-placeholder]::before {
  font-size: 18px !important;
  margin-left: 2px;
  border-radius: 0px !important;
}
.ql-container .ql-editor {
  border-radius: 0 !important;
}
.discardBtn span {
  background: #fafafc !important;
}
.chatActionIcon div button {
  background: #fafafc;
}
.previewText {
  border-radius: 4px !important;
  border: 1px solid #cccc !important;
  padding: 12px !important;
  pointer-events: none !important;
  cursor: not-allowed;
  color: #717171 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  word-wrap: break-word;
}
.previewText a {
  pointer-events: auto !important;
  cursor: pointer !important;
  color: blue !important;
}
.previewText p {
  color: #717171 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.coupon_ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  display: block !important;
}
.profileHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chatProfileImg {
  display: flex;
  align-items: center;
  gap: 14px;
}
.assignImage {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  object-fit: cover;
}
.bgtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #717171;
  margin-bottom: 4px;
}
.specs {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #1b2324;
  margin: 0px 0;
  transition: all 0.3s ease;
  word-break: break-word;
}
.assignUsername {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: #1b2324;
  margin: 0 !important;
  word-break: break-all;
}
.assignState {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: #95a1a2;
}
.tagCheckBox div label span {
  font-family: var(--poppinsFont);
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #1b2324;
}
.tagCheckBox div label {
  cursor: pointer;
}

.inactiveTags {
  pointer-events: none !important;
}
.inactiveTags div label svg {
  fill: #fff !important;
  color: #cacaca !important;
  --checkbox-border-color: #cacaca !important;
}
.inactiveTags .css-67m4gd input[type="checkbox"]:checked + svg {
  --checkbox-border-color: #cacaca !important;
}
.img-info {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.count {
  height: 24px;
  width: 24px;
  background: #355f92;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.msg_sent_time {
  color: #abb0bf;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}
.messageBorder {
  border-bottom: 1px solid #e8eaf3;
  padding-bottom: 16px;
}
.countHeader {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #1b2324;
  margin: 0;
}

.bg-completed {
  background-color: #e4eaf0 !important;
}

.floater-FormConatiner .ql-container {
  font-family: "Poppins", sans-serif;
}

.floater-FormConatiner {
  scrollbar-width: none;
}

.floater-FormConatiner .ql-editor {
  min-height: 59px !important;
  max-height: 200px;
  overflow-y: scroll;
  scrollbar-width: none;
  padding: 12px 0px !important;
}
.floater-FormConatiner .ql-editor.ql-blank::before {
  left: 0 !important;
  right: 0 !important;
}

.clientTabs {
  height: 40px;
  background: var(--skeleton);
  margin-bottom: 10px;
  width: 300px;
}

.ql-editor ol,
.ql-editor ul {
  padding: 0px !important;
}

.searchLoader {
  height: 40px;
  background: var(--skeleton);
  margin-bottom: 10px;
  width: 200px;
}

.selectorLoader {
  height: 40px;
  background: var(--skeleton);
  margin-bottom: 10px;
  width: 100px;
}

.formik-contentTitle {
  height: 20px;
  background: var(--skeleton);
  margin-bottom: 10px;
  width: 100px;
}
.formik-content {
  height: 40px;
  background: var(--skeleton);
  margin-bottom: 10px;
  width: 100%;
}
.formik-skeletonLoader {
  display: flex;
  flex-direction: column;
  padding: 32px 32px 0px 32px;
}
.formik-button-skeleton-loader {
  display: flex;
  gap: 30px;
  width: 50%;
  float: right;
  position: absolute;
  bottom: 0;
  right: 32px;
  div {
    height: 40px;
    background: var(--skeleton);
    margin-bottom: 10px;
    width: 100%;
  }
}

.icon-blue svg path {
  fill: #1e3653;
  margin: 0 !important;
}

.ql-snow .ql-tooltip {
  border-radius: 4px !important;
}
